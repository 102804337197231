body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

header {
  background-color: #004080;
  color: white;
  padding: 10px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

header img {
  height: 40px;
  margin-right: 20px;
}

.App {
  max-width: 800px;
  margin: 20px auto;
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

textarea, input[type="number"] {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  background-color: #004080;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  align-self: flex-start;
}

button:hover {
  background-color: #003366;
}

.results {
  margin-top: 20px;
}

h2 {
  color: #004080;
}

.result-table table {
  width: 100%;
  border-collapse: collapse;
}

.result-table th, .result-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.result-table th {
  background-color: #f2f2f2;
}

.best-match {
  background: #fff;
  border: 1px solid #004080;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust height as needed */
}

@media (max-width: 600px) {
  .App {
    padding: 10px;
  }

  textarea, input[type="number"] {
    width: 100%;
    box-sizing: border-box;
  }

  button {
    width: 100%;
  }

  header {
    flex-direction: column;
  }

  header img {
    margin: 0 0 10px 0;
  }
}
